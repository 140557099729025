<template>
    <v-card class="elevation-0">
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Basic information") }}
            </h3>
        </v-card-title>
        <v-card-text class="p-1">
            <v-layout wrap>
                <Fragment v-if="manageProduct">
                    <v-flex xs6 sm2 md2>
                        <v-checkbox
                            :input-value="product.export_web"
                            color="primary"
                            :label="$t('Status Web')"
                            :disabled="!manageProduct && readonly"
                            @change="
                                $emit('updateProduct', $event, 'export_web')
                            "
                            @input="
                                $emit('updateProduct', $event, 'export_web')
                            "
                        ></v-checkbox>
                    </v-flex>
                    <v-flex xs6 sm2 md2>
                        <v-checkbox
                            :input-value="product.shoppable"
                            color="primary"
                            :disabled="!manageProduct && readonly"
                            :label="$t('Shoppable')"
                            @change="
                                $emit('updateProduct', $event, 'shoppable')
                            "
                        ></v-checkbox>
                    </v-flex>
                    <v-flex xs6 sm2 md2>
                        <v-checkbox
                            :input-value="product.highlighted"
                            color="primary"
                            :disabled="!manageProduct && readonly"
                            :label="$t('Highlighted')"
                            @change="
                                $emit('updateProduct', $event, 'highlighted')
                            "
                        ></v-checkbox>
                    </v-flex>
                    <v-flex xs6 sm2 md2>
                        <v-checkbox
                            :input-value="product.datasheet"
                            color="primary"
                            :disabled="!manageProduct && readonly"
                            :label="$t('Datasheet')"
                            @change="
                                $emit('updateProduct', $event, 'datasheet')
                            "
                        ></v-checkbox>
                    </v-flex>
                    <v-flex xs6 sm2 md2>
                        <v-checkbox
                            :input-value="product.show_calculator"
                            color="primary"
                            :disabled="!manageProduct && readonly"
                            :label="$t('Show calculator')"
                            @change="
                                $emit('updateProduct', $event, 'show_calculator')
                            "
                        ></v-checkbox>
                    </v-flex>
                    <v-flex xs6 sm2 md2>
                        <v-checkbox
                            :input-value="product.is_new"
                            color="primary"
                            :disabled="!manageProduct && readonly"
                            :label="$t('Is new')"
                            @change="
                                $emit('updateProduct', $event, 'is_new')
                            "
                        ></v-checkbox>
                    </v-flex>
                </Fragment>
                <v-flex xs6 sm4 md4>
                    <v-text-field
                        :value="product.tenant && product.tenant.name[language]"
                        :label="$t('Tenant')"
                        :disabled="readonly"
                        readonly
                    >
                        <template #append>
                            <navisionIcon />
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs6 sm4 md4>
                    <v-text-field
                        :value="product.item_number"
                        :label="$t('Product no')"
                        :disabled="readonly"
                        readonly
                    >
                        <template #append>
                            <navisionIcon />
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs6 sm4 md4>
                    <v-text-field
                        :value="brandCaption"
                        :label="$t('Brand')"
                        readonly
                        :disabled="readonly"
                        persistent-hint
                    >
                        <template #append>
                            <navisionIcon />
                        </template>
                </v-text-field>
                </v-flex>
                <v-flex xs6 sm4 md4>
                    <!-- 
                    <v-select
                        :value="
                            product.product_group && product.product_group.id
                        "
                        :items="attributes.product_groups.filter(item => item.tenant_id === product.tenant.id)"
                        :item-text="
                            item =>
                                item.name[language] ||
                                item.name[fallbackLanguage]
                        "
                        item-value="id"
                        :readonly="true"
                        :disabled="readonly"
                        :label="$t('Product group')"
                        small-chips
                        @input="
                            $emit('updateProduct', $event, 'product_group_id')
                        "
                    ></v-select>
                    -->
                    <v-text-field
                        :value="productGroup"
                        placeholder=" "
                        :label="$t('Product group')"
                        readonly
                    >
                    <template #append>
                        <navisionIcon />
                    </template>
                </v-text-field> 
                </v-flex>
                <v-flex xs6 sm4 md4>
                    <v-text-field
                        v-if="
                            product.product_group_code &&
                                product.product_group_code.name
                        "
                        :value="product.product_group_code.name[language]"
                        placeholder=" "
                        :label="$t('Product group code')"
                        hint="Herstellungsart"
                        readonly
                        :disabled="readonly"
                    >
                        <template #append>
                            <navisionIcon />
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs6 sm4 md4>
                    <v-text-field
                        :value="product.gtin"
                        placeholder=" "
                        :label="$t('GTIN')"
                        readonly
                        :disabled="readonly"
                    >
                        <template #append>
                            <navisionIcon />
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md8>
                    <translateable
                        :value="product.name"
                        :default-language="language"
                        :multiline="false"
                        :label="$t('Artikelname Navision')"
                        readonly
                        :disabled="readonly"
                    >
                        <template #prepend>
                            <navisionIcon />
                        </template>
                    </translateable>
                </v-flex>
                <v-flex xs12 sm6 md4>
                    <v-text-field
                        :value="product.registry_numbers"
                        placeholder=" "
                        :label="$t('Registry numbers')"
                        readonly
                        :disabled="readonly"
                    >
                        <template #append>
                            <navisionIcon />
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs12>
                    <translateable
                        :value="product.extended_name"
                        :default-language="language"
                        :multiline="true"
                        :readonly="readonly"
                        :label="$t('Name Endkunde')"
                        @input="$emit('updateProduct', $event, 'extended_name')"
                    >
                        <template #prepend>
                            <mandatoryIcon />
                        </template>
                    </translateable>
                </v-flex>
                <v-flex xs6>
                    <translateable
                        :value="product.slug"
                        :default-language="language"
                        :multiline="false"
                        :label="$t('Slug')"
                        :readonly="readonly"
                        :rules="rules.slug"
                        @input="$emit('updateProduct', $event, 'slug')"
                    >
                        <v-tooltip slot="prepend" right>
                            <template #activator="{ on }">
                                <v-icon color="yellow darken-2" light v-on="on"
                                    >help_outline</v-icon
                                >
                            </template>
                            <span>{{ $t("slug_hint") }}</span>
                        </v-tooltip>
                    </translateable>
                </v-flex>
                <v-flex xs6>
                    <translateable
                        :value="product.qr_code_url"
                        :default-language="language"
                        :multiline="false"
                        :readonly="readonly"
                        :label="$t('QR-Code URL')"
                        @input="$emit('updateProduct', $event, 'qr_code_url')"
                    >
                        <v-tooltip slot="prepend" right>
                            <template #activator="{ on }">
                                <v-icon color="yellow darken-2" light v-on="on"
                                    >help_outline</v-icon
                                >
                            </template>
                            <span>{{ $t("QR Code URL") }}</span>
                        </v-tooltip>
                    </translateable>
                </v-flex>
                <v-flex xs12>
                    <h4 class="text-h5 font-weight-thin primary--text">
                        {{ $t("Pricing") }}
                    </h4>
                </v-flex>
                <v-flex xs12 sm6 md4>
                    <v-text-field
                        :value="product.unit_price"
                        placeholder=" "
                        :label="$t('Unit price')"
                        readonly
                        :disabled="readonly"
                    >
                        <template #append>
                            <navisionIcon />
                        </template>
                        <v-tooltip slot="prepend" right>
                            <template #activator="{ on }">
                                <v-icon color="yellow darken-2" light v-on="on"
                                    >help_outline</v-icon
                                >
                            </template>
                            <span>{{ $t("Empfohlener Verkaufspreis") }}</span>
                        </v-tooltip>
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                    <v-text-field
                        :value="product.vat_value"
                        placeholder=" "
                        :label="$t('MWSt. Gruppe und Satz')"
                        readonly
                        :prefix="product.vat_code"
                        :disabled="readonly"
                    >
                        <template #append>
                            <navisionIcon />
                        </template>    
                        <v-tooltip slot="prepend" right>
                            <template #activator="{ on }">
                                <v-icon color="yellow darken-2" light v-on="on"
                                    >help_outline</v-icon
                                >
                            </template>
                            <span>{{ $t("MWSt. Satz und Gruppe") }}</span>
                        </v-tooltip>
                    </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                    <v-text-field
                        :value="product.base_price"
                        placeholder=" "
                        :label="$t('Base price per kilo')"
                        readonly
                        :disabled="readonly"
                    >
                    <template #append>
                        <navisionIcon />
                    </template>    
                    <v-tooltip slot="prepend" right>
                            <template #activator="{ on }">
                                <v-icon color="yellow darken-2" light v-on="on"
                                    >help_outline</v-icon
                                >
                            </template>
                            <span>{{ $t("Base price per kilo") }}</span>
                        </v-tooltip>
                    </v-text-field>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
import { Fragment } from "vue-fragment";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        Fragment,
        translateable: require("$comp/shared/TranslateableText").default,
        navisionIcon: require("$comp/ui/NavisionIcon").default,
        mandatoryIcon: require("$comp/ui/MandatoryIcon").default
    },

    props: {
        product: {
            type: Object,
            default: () => {
                return {
                    tenant: {
                        name: {}
                    },
                    brand: {
                        name: null
                    }
                };
            }
        },
        language: {
            type: String,
            required: true
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        },
        manageProduct: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            rules: {
                slug: [
                    v => !!v || this.$t("Slug is required"),
                    v => /^[a-z0-9-]+$/.test(v) || this.$t("Invalid slug")
                ]
            }
        };
    },
    computed: {
        ...mapGetters({
            attributes: "master-data/attributes"
        }),

        productGroup() {
            return this.product.product_group && this.product.product_group.name
                ? this.product.product_group.name[this.language]
                : " ";
        },

        productGroupCode() {
            return this.product.product_group_code &&
                this.product.product_group_code.name
                ? this.product.product_group_code.name[this.language]
                : " ";
        },

        brandCaption() {
            return this.product.brand && this.product.brand.name
                ? this.product.brand.name
                : " ";
        }
    },
    methods: {
        ...mapActions({
            setLoading: "ui/setLoading"
        })
    }
};
</script>
