<template>
    <v-card class="elevation-0">
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Relations") }}
            </h3>
        </v-card-title>
        <v-card-text>
            <v-container py-0 px-2 grid-list-xs fluid>
                <v-layout wrap>
                    <v-flex xs12>
                        <v-autocomplete
                            v-model="recommended"
                            :items="itemsRecommended"
                            :search-input.sync="searchRecommended"
                            item-text="item_number"
                            item-value="id"
                            hide-selected
                            :loading="searchRecommendedLoading"
                            :label="$t('Recommended products')"
                            multiple
                            deletable-chips
                            cache-items
                            :filter="filterProducts"
                            persistent-hint
                            small-chips
                            return-object
                            :placeholder="
                                $t('Mit der Eingabe beginnen um zu suchen ...')
                            "
                            @input="update($event, 'recommended_products')"
                        >
                            <template #no-data>
                                <v-list-item>
                                    <v-list-item-title>
                                        {{
                                            $t(
                                                "Start typing to search products."
                                            )
                                        }}
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                            <template #item="data">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ data.item.item_number }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span
                                            class="primary--text font-weight-bold"
                                        >
                                            {{
                                                tenantIdToString(
                                                    data.item.tenant_id
                                                )
                                            }}
                                        </span>
                                        | {{ data.item.name[language] }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                            <template slot="no-data">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            No results matching "<strong
                                                >{{
                                                    searchRecommended
                                                }} </strong
                                            >".
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-2"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>
                                    {{
                                        $t(
                                            "zum Produkt passende Produkt, bsp. Hortensienblau + Rhododünger"
                                        )
                                    }}
                                </span>
                            </v-tooltip>
                        </v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                        <v-autocomplete
                            :value="related"
                            :items="itemsRelated"
                            :search-input.sync="searchRelated"
                            item-text="item_number"
                            item-value="id"
                            hide-selected
                            :loading="searchRelatedLoading"
                            :label="$t('Related products')"
                            multiple
                            deletable-chips
                            cache-items
                            :filter="filterProducts"
                            persistent-hint
                            small-chips
                            :placeholder="
                                $t('Mit der Eingabe beginnen um zu suchen ...')
                            "
                            @input="update($event, 'related_products')"
                        >
                            <template #append>
                                <mandatoryIcon />
                            </template>
                            <template #no-data>
                                <v-list-item>
                                    <v-list-item-title>
                                        {{
                                            $t(
                                                "Start typing to search products."
                                            )
                                        }}
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                            <template #item="data">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ data.item.item_number }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span
                                            class="primary--text font-weight-bold"
                                        >
                                            {{
                                                tenantIdToString(
                                                    data.item.tenant_id
                                                )
                                            }}
                                        </span>
                                        | {{ data.item.name[language] }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                            <template slot="no-data">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            No results matching "<strong>{{
                                                searchRelated
                                            }}</strong
                                            >".
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-2"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>
                                    {{
                                        $t(
                                            "gleiche Produkte mit anderen Verpackungsgrössen"
                                        )
                                    }}
                                </span>
                            </v-tooltip>
                        </v-autocomplete>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { api, defaultLanguageIso, tenants } from "~/config";

export default {
    components: {
        mandatoryIcon: require("$comp/ui/MandatoryIcon").default
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        dialog: {
            type: Boolean,
            default: false
        },
        language: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        manageProduct: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            searchRecommended: null,
            searchRelated: null,
            isLoading: false,
            tenants: tenants,
            itemsRecommended: [...this.product.recommended_products],
            selectedLanguage: defaultLanguageIso,
            itemsRelated: [...this.product.related_products],
            searchRecommendedLoading: false,
            searchRelatedLoading: false,
            recommended: this.product.recommended_products,
            related: this.product.related_products
        };
    },

    computed: {
        ...mapGetters({
            tenant: "auth/tenant"
        })
    },

    watch: {
        searchRecommended(val) {
            if (!val || val.length < 3) return;
            this.fetchRecommendedDebounced(val);
        },

        searchRelated(val) {
            if (!val || val.length < 3) return;
            this.fetchRelatedDebounced(val);
        }
    },

    methods: {
        ...mapActions({
            searchProducts: "products/search"
        }),

        fetchRecommendedDebounced(val) {
            // cancel pending call
            clearTimeout(this._timerRecommendeId);

            // delay new call 1000ms
            this._timerRecommendeId = setTimeout(() => {
                this.itemsRecommended = [];

                this.searchRecommendedLoading = true;

                axios
                    .get(
                        api.path("products.queryWithinTenant", {
                            term: val,
                            tenant: this.tenant
                        })
                    )
                    .then(r => r.data)
                    .then(products => {
                        this.itemsRecommended = products.data;
                    })
                    .finally(() => (this.searchRecommendedLoading = false));
            }, 1000);
        },

        fetchRelatedDebounced(val) {
            // cancel pending call
            clearTimeout(this._timerRelatedId);

            // delay new call 1000ms
            this._timerRelatedId = setTimeout(() => {
                this.itemsRelated = [];

                this.searchRelatedLoading = true;

                axios
                    .get(
                        api.path("products.queryWithinTenant", {
                            term: val,
                            tenant: this.tenant
                        })
                    )
                    .then(r => r.data)
                    .then(products => {
                        this.itemsRelated = products.data;
                    })
                    .finally(() => (this.searchRelatedLoading = false));
            }, 1000);
        },

        tenantIdToString(id) {
            let tenant = this.tenants.filter(obj => {
                return obj.id === id;
            });

            if (tenant.length > 0) {
                return this.$t("tenant_" + tenant[0].abbr);
            } else {
                return "";
            }
        },

        update(event, field) {
            // var filtered = event.filter(el => el !== undefined);
            this.$emit("updateProduct", event, field);
        },

        filterProducts(item, queryText) {
            return (
                item.item_number
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.name[this.selectedLanguage]
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        }
    }
};
</script>
