var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_c('h3',{staticClass:"text-h5 font-weight-thin primary--text pl-4 pt-2"},[_vm._v(" "+_vm._s(_vm.$t("Media"))+" ")])]),_c('v-card-text',[_c('v-container',{attrs:{"py-0":"","px-2":"","grid-list-xs":"","testfluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h3',{staticClass:"font-weight-thin"},[_vm._v(" "+_vm._s(_vm.$t("Product image"))+" "),_c('mandatoryIcon')],1),_c('media-selector',{attrs:{"value":_vm.product.image,"multiple":false},on:{"change":function($event){return _vm.$emit('updateProduct', $event, 'image')},"input":function($event){return _vm.$emit('updateProduct', $event, 'image')}}}),_c('v-divider',{staticClass:"my-4",attrs:{"color":"primary"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('h3',{staticClass:"font-weight-thin"},[_vm._v(_vm._s(_vm.$t("Documents")))])]),_c('v-flex',{attrs:{"xs12":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("Safety data sheet")))]),_c('br'),_c('media-selector',{attrs:{"value":_vm.product.safety_data_sheet,"lang":_vm.language,"multiple":true,"accept":['pdf']},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event,
                                'safety_data_sheet'
                            )},"change":function($event){return _vm.$emit(
                                'updateProduct',
                                $event,
                                'safety_data_sheet'
                            )}}}),_c('v-divider',{staticClass:"mt-2"})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("Logo")))]),_c('br'),_c('media-selector',{attrs:{"value":_vm.product.logo,"multiple":false},on:{"change":function($event){return _vm.$emit('updateProduct', $event, 'logo')},"input":function($event){return _vm.$emit('updateProduct', $event, 'logo')}}}),_c('v-divider',{staticClass:"mt-2"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }