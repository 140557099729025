<template>
    <v-card class="elevation-0">
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Media") }}
            </h3>
        </v-card-title>
        <v-card-text>
            <v-container py-0 px-2 grid-list-xs testfluid>
                <v-layout wrap>
                    <v-flex xs12>
                        <h3 class="font-weight-thin">
                            {{ $t("Product image") }} <mandatoryIcon />
                        </h3>
                        
                        <media-selector
                            :value="product.image"
                            :multiple="false"
                            @change="$emit('updateProduct', $event, 'image')"
                            @input="$emit('updateProduct', $event, 'image')"
                        ></media-selector>
                        <v-divider color="primary" class="my-4"></v-divider>
                    </v-flex>

                    <v-flex xs12>
                        <h3 class="font-weight-thin">{{ $t("Documents") }}</h3>
                    </v-flex>
                    <v-flex xs12>
                        <span>{{ $t("Safety data sheet") }}</span>
                        <br />
                        <media-selector
                            :value="product.safety_data_sheet"
                            :lang="language"
                            :multiple="true"
                            :accept="['pdf']"
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'safety_data_sheet'
                                )
                            "
                            @change="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'safety_data_sheet'
                                )
                            "
                        ></media-selector>
                        <v-divider class="mt-2"></v-divider>
                    </v-flex>

                    <v-flex xs12>
                        <span>{{ $t("Logo") }}</span>
                        <br />
                        <media-selector
                            :value="product.logo"
                            :multiple="false"
                            @change="$emit('updateProduct', $event, 'logo')"
                            @input="$emit('updateProduct', $event, 'logo')"
                        ></media-selector>
                        <v-divider class="mt-2"></v-divider>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    components: {
        "media-selector": require("$comp/shared/MediaValetSelector").default,
        mandatoryIcon: require("$comp/ui/MandatoryIcon").default
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        language: {
            type: String,
            required: true
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        product: {
            type: Object,
            default: () => {
                return {};
            }
        },
        readonly: {
            type: Boolean,
            required: true
        },
        manageProduct: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {};
    },
    methods: {}
};
</script>
